<template>
    <div>
        <div>
            <div v-if="uploading">
                <radial-progress-bar :diameter="186" :completed-steps=progress_total :total-steps="100"
                                     :stroke-width="18" :inner-stroke-width="16"
                                     :start-color="'#69CCF7'" :stop-color="'#3490dc'"
                                     :inner-stroke-color="'#BDBDBD'">
                    <radial-progress-bar :diameter="150" :completed-steps=progress :total-steps="100"
                                         :stroke-width="8" :inner-stroke-width="8"
                                         :start-color="'#a9a9a9'" :stop-color="'#778899'"
                                         :inner-stroke-color="'#BDBDBD'">
                        <div class="font-weight-bold text-primary">{{ $t("Uploading") }}</div>
                        <div class="font-weight-bold">
                            <span class="progress-one">{{ progress }}%</span> /
                            <span class="progress-total">{{ progress_total }}%</span>
                        </div>
                        <button type="button" name="cancel" class="btn btn-danger btn-sm" @click="onCancel">
                            {{ $t("Cancel") }}
                        </button>
                    </radial-progress-bar>
                </radial-progress-bar>
                <div class="font-weight-bold progress-one">{{ $t("File-Name") }}：{{upfilename}}</div>
            </div>
            <div v-else-if="downloading">
                <radial-progress-bar :diameter="186" :completed-steps=progress :total-steps="100"
                                     :stroke-width="18" :inner-stroke-width="18"
                                     :start-color="'#BBFF42'" :stop-color="'#83FF52'"
                                     :inner-stroke-color="'#BDBDBD'">
                    <div class="font-weight-bold text-success">{{ $t("Downloading") }}</div>
                    <div class="font-weight-bold text-success">{{ progress }}%</div>
                    <button type="button" name="cancel" class="btn btn-danger btn-sm" @click="onCancel">
                        {{ $t("Cancel") }}
                    </button>
                </radial-progress-bar>
                <div class="font-weight-bold text-success">{{ $t("File-Name") }}：{{dlfilename}}</div>
            </div>
            <div v-else id="drop-area" @dragleave.prevent @dragover.prevent @drop.prevent="onDropFile">
                <div v-if="!uploading">
                    <div class="p-3">
                        <p class="drag-drop-info">{{ $t("Upload-Area-TXT-01") }}</p>
                        <p>{{ $t("Upload-Area-TXT-02") }}</p>
                    </div>
                    <p>
                        <input id="file-up" type="file" name="file[]" @change="onSelectFile" multiple>
                        <label class="btn btn-primary btn-sm" for="file-up">{{ $t("Upload-File") }}</label>
                        <input id="folder-up" type="file" name="file[]" @change="onSelectFolder" webkitdirectory>
                        <label class="btn btn-primary btn-sm" for="folder-up">{{ $t("Upload-Folder") }}</label>
                        </br>{{ $t("Upload-Area-TXT-03") }}
                    </p>
                    <button type="button" class="btn btn-primary btn-sm" @click="onUpload">
                        <font-awesome-icon :icon="['fas', 'upload']" />
                        {{ $t("Upload") }}
                    </button>
                </div>
            </div>
            <div v-if="up_files.length !== 0" id="up-file-area">
                <div v-for="(file,index) in up_files" class="up-file">
                    <span>
                        <span v-if="file['type'] === 'file'">
                            <font-awesome-icon :icon="['far','file']" class="file-icon" />
                        </span>
                        <span v-else>
                            <font-awesome-icon :icon="['far','folder']" class="folder-icon" />
                        </span>
                        {{file["name"]}}
                        <button type="button" class="btn btn-outline-danger btn-sm no-line" @click="onUpFileDelete(index)">
                            {{convertSizeUnit(file["size"])}}
                            <font-awesome-icon :icon="['far','window-close']" />
                        </button>
                    </span>
                </div>
            </div>
        </div>

        <div style="padding: 20px">
            <div>
                <button type="button" class="btn btn-secondary btn-sm" @click="onShowModal('','jump')">
                    <font-awesome-icon :icon="['fas', 'arrow-right']" />
                    {{ $t("Jump-Folder") }}
                </button>
                <button v-if="r_c_json[0] !== 'sub03'" type="button" class="btn btn-primary btn-sm" @click="onShowModal('','mkdir')">
                    <font-awesome-icon :icon="['far', 'folder']" />
                    {{ $t("Mkdir") }}
                </button>
                <button v-else type="button" class="btn btn-outline-primary btn-sm">
                    <font-awesome-icon :icon="['far', 'folder']" />
                    {{ $t("Mkdir") }}
                </button>
                <button v-if="check_f.length === 0 && check_d.length === 0" type="button" class="btn btn-outline-secondary btn-sm">
                    <font-awesome-icon :icon="['fas', 'download']" />
                    {{ $t("All-Download") }}
                </button>
                <button v-else type="button" class="btn btn-secondary btn-sm" @click="onDownloadAll">
                    <font-awesome-icon :icon="['fas', 'download']" />
                    {{ $t("All-Download") }}
                </button>
                <button v-if="check_d.length === 0 && check_f.length === 0" type="button" class="btn btn-outline-danger btn-sm">
                    <font-awesome-icon :icon="['far', 'trash-alt']" />
                    {{ $t("All-Delete") }}
                </button>
                <button v-else type="button" class="btn btn-danger btn-sm" @click="onShowModal('','deleteall')">
                    <font-awesome-icon :icon="['far', 'trash-alt']" />
                    {{ $t("All-Delete") }}
                </button>
            </div>
        </div>

        <modal name="modal-01" :width="500" :adaptive="true">
            <div class="modal-header">
                <h5 v-if="modal_type === 'mkdir'" class="modal-title" id="label1">{{ $t("Mkdir") }}</h5>
                <h5 v-else-if="modal_type === 'drename'" class="modal-title" id="label1">{{ $t("Rename-Folder") }}</h5>
                <h5 v-else-if="modal_type === 'jump'" class="modal-title" id="label1">{{ $t("Jump-Folder") }}</h5>
                <h5 v-else class="modal-title" id="label1">{{ $t("Delete-Modal-Title") }}</h5>
                <button @click="hide" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div v-if="modal_type === 'jump'" class="modal-body text-center">
                <v-jstree :data="tree_data" allow-batch whole-row @item-click="onTreeClick"></v-jstree>
            </div>
            <div class="modal-body text-center">
                <span v-if="modal_type === 'ddelete' || modal_type === 'fdelete'" class="text-primary">{{modal_value}}</span>
                <span v-if="modal_type === 'ddelete'">{{ $t("Delete-Modal-Confirm-02") }}</span>
                <span v-if="modal_type === 'deleteall'">{{ $t("Delete-Modal-Confirm-03") }}</span>
                <span v-if="modal_type === 'fdelete'">{{ $t("Delete-Modal-Confirm-01") }}</span>
                <span v-if="modal_type === 'mkdir'">{{ $t("Folder-Name") }}：<input type="txt" name="mkdir" ref="mkdir" maxlength="255"></span>
                <span v-if="modal_type === 'drename'">{{ $t("Folder-Name") }}：<input type="txt" name="drename" ref="drename" maxlength="255"></span>
                <span v-if="modal_type === 'jump'">{{ $t("Folder-Path") }}：/home/ <input type="txt" name="jump" ref="jump"></span>
            </div>
            <div class="modal-footer">
                <button @click="hide" type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t("Close") }}</button>
                <button v-if="modal_type === 'jump'" type="button" class="btn btn-primary" @click="onJumpFolder">{{ $t("Move") }}</button>
                <button v-if="modal_type === 'mkdir'" type="button" class="btn btn-primary" @click="onMkdir">{{ $t("Make") }}</button>
                <button v-if="modal_type === 'drename'" type="button" class="btn btn-primary" @click="onFolderRename(modal_value)">{{ $t("Modify") }}</button>
                <button v-if="modal_type === 'deleteall'" type="button" class="btn btn-danger" @click="onDeleteAll">{{ $t("Delete") }}</button>
                <button v-if="modal_type === 'ddelete' || modal_type === 'fdelete'" type="button" class="btn btn-danger" @click="onDelete(modal_value,modal_type)">{{ $t("Delete") }}</button>
            </div>
        </modal>

        <modal name="modal-02" :width="700" height="auto" :scrollable="true">
            <div class="modal-header">
                <h5 class="modal-title" id="label1">{{ $t("Jump-Folder") }}</h5>
            </div>
            <div class="modal-body text-center border-bottom">
                <div class="text-left">{{ $t("Jump-Modal-Label-01") }}</div>
                <v-jstree :data="tree_data"
                          :item-events="itemEvents"
                          allow-batch
                          whole-row
                          ref="tree"
                          @item-click="onTreeClick">
                    <template scope="_">
                        <div style="display: inherit; width: 200px">
                            <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
                            {{_.model.text}}
                            <button class="btn btn-link btn-sm" style="border: 0px; background-color: transparent; cursor: pointer;" @click="customItemClick(_.vm, _.model, $event)">
                                <font-awesome-icon :icon="['fas', 'arrow-right']" />
                            </button>
                        </div>
                    </template>
                </v-jstree>
            </div>
            <div class="modal-body text-center">
                <div class="text-left">{{ $t("Jump-Modal-Label-02") }}</div>
                <div>{{ $t("Folder-Path") }}：/home/ <input type="txt" name="jump" ref="jump"></div>
            </div>
            <div class="modal-footer">
                <button @click="hide2" type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t("Close") }}</button>
                <button type="button" class="btn btn-primary" @click="onJumpFolder">{{ $t("Move") }}</button>
            </div>
        </modal>

        <div v-if="r_c_json[0] === 'home'" class="p-1 bg-info text-white font-weight-bold">/home</div>
        <div v-else class="p-1 bg-info text-white font-weight-bold">/home/{{r_c_json[1]}}</div>

        <!--テーブル-->
        <table class="table table-hover table-bordered table-sm table-light">
            <colgroup>
                <col style="width: 150px;">
                <col style="width: 25px;">
                <col style="width: 250px;">
                <col style="width: 100px;">
                <col style="width: 50px;">
            </colgroup>
            <thead class="thead-dark">
                <tr class="text-center">
                    <th>
                        <button v-if="order_item === 'date' && order_status" class="btn btn-outline-light btn-sm no-line" @click="onItemSort('date',false,true)">
                            {{ $t("Update-Date") }}
                            <font-awesome-icon :icon="['fas','arrow-up']" />
                        </button>
                        <button v-else-if="order_item === 'date' && !order_status" class="btn btn-outline-light btn-sm no-line" @click="onItemSort('date',true,true)">
                            {{ $t("Update-Date") }}
                            <font-awesome-icon :icon="['fas','arrow-down']" />
                        </button>
                        <button v-else class="btn btn-outline-light btn-sm no-line" @click="onItemSort('date',true,true)">
                            {{ $t("Update-Date") }}
                        </button>
                    </th>
                    <th colspan="2">
                        <button v-if="order_item === 'name' && order_status" class="btn btn-outline-light btn-sm no-line" @click="onItemSort('name',false,true)">
                            {{ $t("Folder-File") }}
                            <font-awesome-icon :icon="['fas','arrow-up']" />
                        </button>
                        <button v-else-if="order_item === 'name' && !order_status" class="btn btn-outline-light btn-sm no-line" @click="onItemSort('name',true,true)">
                            {{ $t("Folder-File") }}
                            <font-awesome-icon :icon="['fas','arrow-down']" />
                        </button>
                        <button v-else class="btn btn-outline-light btn-sm no-line" @click="onItemSort('name',true,true)">
                            {{ $t("Folder-File") }}
                        </button>
                    </th>
                    <th>
                        <button v-if="order_item === 'size' && order_status" class="btn btn-outline-light btn-sm no-line" @click="onItemSort('size',false,true)">
                            {{ $t("Size") }}
                            <font-awesome-icon :icon="['fas','arrow-up']" />
                        </button>
                        <button v-else-if="order_item === 'size' && !order_status" class="btn btn-outline-light btn-sm no-line" @click="onItemSort('size',true,true)">
                            {{ $t("Size") }}
                            <font-awesome-icon :icon="['fas','arrow-down']" />
                        </button>
                        <button v-else class="btn btn-outline-light btn-sm no-line" @click="onItemSort('size',true,true)">
                            {{ $t("Size") }}
                        </button>
                    </th>
                    <th><button class="btn btn-outline-light btn-sm no-line">{{ $t("Delete") }}</button></th>
                </tr>
            </thead>
            <tbody v-if="order_status">
                <tr v-if="r_c_json[0] !== 'home'">
                    <td class="text-center">-</td>
                    <td colspan="2">
                        <button type="button" class="btn btn-link btn-sm text-left" @click="onReViewPrev">
                            <font-awesome-icon :icon="['fas', 'arrow-left']" />
                            {{ $t("Previous-Folder") }}
                        </button>
                    </td>
                    <td class="text-center">-</td>
                    <td class="text-center">-</td>
                </tr>
                <tr v-for="(d,index) in r_d_json">
                    <td class="text-center">{{d[0]}}</td>
                    <td class="text-center"><input type="checkbox" v-model="check_d" :value=index></td>
                    <td>
                        <button type="button" class="btn btn-link btn-sm text-left" :value=d[1] @click="onReViewFord">
                            <font-awesome-icon :icon="['far','folder-open']" />
                            {{d[1]}}
                        </button>
                        <button type="button" name="ddelete" class="btn btn-outline-secondary btn-sm no-line" @click="onShowModal(d[1],'drename')" style="width: 30px;">
                            <font-awesome-icon :icon="['fas','pen']" />
                        </button>     
                    </td>
                    <td class="text-right">{{d[2]}}</td>
                    <td class="text-center">
                        <button type="button" name="ddelete" class="btn btn-outline-danger btn-sm" @click="onShowModal(d[1],'ddelete')" style="width: 50px;">
                            <font-awesome-icon :icon="['far','trash-alt']" />
                        </button>
                    </td>
                </tr>
                <tr v-for="(f,index) in r_f_json">
                    <td class="text-center">{{f[0]}}</td>
                    <td class="text-center"><input type="checkbox" v-model="check_f" :value=index></td>
                    <td>
                        <button v-if="f[5] === 0" type="button" name="download" class="btn btn-outline-secondary btn-sm text-left" @click="onDownload(index)">
                            <font-awesome-icon :icon="['fas','download']" />
                            {{f[1]}}
                        </button>
                        <button v-else type="button" name="download" class="btn btn-outline-secondary btn-sm">
                            <font-awesome-icon :icon="['fas','spinner']" spin />
                            {{f[1]}}
                        </button>
                    </td>
                    <td class="text-right">{{f[2]}}</td>
                    <td class="text-center">
                        <button type="button" name="fdelete" class="btn btn-outline-danger btn-sm" @click="onShowModal(f[1],'fdelete')" style="width: 50px;">
                            <font-awesome-icon :icon="['far','trash-alt']" />
                        </button>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-if="r_c_json[0] !== 'home'">
                    <td class="text-center">-</td>
                    <td colspan="2">
                        <button type="button" class="btn btn-link btn-sm text-left" @click="onReViewPrev">
                            <font-awesome-icon :icon="['fas', 'arrow-left']" />
                            {{ $t("Previous-Folder") }}
                        </button>
                    </td>
                    <td class="text-center">-</td>
                    <td class="text-center">-</td>
                </tr>
                <tr v-for="(f,index) in r_f_json">
                    <td class="text-center">{{f[0]}}</td>
                    <td class="text-center"><input type="checkbox" v-model="check_f" :value=index></td>
                    <td>
                        <button v-if="f[5] === 0" type="button" name="download" class="btn btn-outline-secondary btn-sm text-left" @click="onDownload(index)">
                            <font-awesome-icon :icon="['fas','download']" />
                            {{f[1]}}
                        </button>
                        <button v-else type="button" name="download" class="btn btn-outline-secondary btn-sm">
                            <font-awesome-icon :icon="['fas','spinner']" spin />
                            {{f[1]}}
                        </button>
                    </td>
                    <td class="text-right">{{f[2]}}</td>
                    <td class="text-center">
                        <button type="button" name="fdelete" class="btn btn-outline-danger btn-sm" @click="onShowModal(f[1],'fdelete')" style="width: 50px;">
                            <font-awesome-icon :icon="['far','trash-alt']" />
                        </button>
                    </td>
                </tr>
                <tr v-for="(d,index) in r_d_json">
                    <td class="text-center">{{d[0]}}</td>
                    <td class="text-center"><input type="checkbox" v-model="check_d" :value=index></td>
                    <td>
                        <button type="button" class="btn btn-link btn-sm text-left" :value=d[1] @click="onReViewFord">
                            <font-awesome-icon :icon="['far','folder-open']" />
                            {{d[1]}}
                        </button>
                        <button type="button" name="ddelete" class="btn btn-outline-secondary btn-sm no-line" @click="onShowModal(d[1],'drename')" style="width: 30px;">
                            <font-awesome-icon :icon="['fas','pen']" />
                        </button>     
                    </td>
                    <td class="text-right">{{d[2]}}</td>
                    <td class="text-center">
                        <button type="button" name="ddelete" class="btn btn-outline-danger btn-sm" @click="onShowModal(d[1],'ddelete')" style="width: 50px;">
                            <font-awesome-icon :icon="['far','trash-alt']" />
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style>
.v--modal{
  top: 100px !important;
  left: 0px !important;
  height: 100% !important;
}
#drop-area {
    background-color:#e6e6e6;
    border:solid 3px #b3b3b3;
    color:#b3b3b3;
    min-height:50px;
    max-width:500px;
    padding:10px;
    text-align:center;
    border-style:dashed;
}
#up-file-area {
    border:solid 2px #b3b3b3;
    max-height:250px;
    max-width:500px;
    padding:5px;
    text-align:left;
    overflow-y: scroll;
}
td {
    vertical-align: middle !important;
}
.table > tbody > tr > td{
    border:1px solid #e4e4e4;
}
.up-file {
    border:1px solid #e4e4e4;
    border-radius:2px;
    padding:0 5px 0 5px;
}
input[type="file"] {
    display: none;
}
.file-icon{
    color: #2d7fd1;
}
.folder-icon{
    color: #f79053;
}
.no-line{
    line-height: none;
    border: none;
}
.progress-one {
    color: #778899;
}
.progress-total {
    color: #3490dc;
}
input[name="jump"] {
    width: 300;
}
.icon-state-default {
    color: gray;
}
.icon-state-danger {
    color: red;
}
.icon-state-warning {
    color: yellow;
}
.icon-state-success {
    color: green;
}
</style>

<script>
import RadialProgressBar from 'vue-radial-progress';
export default {
    components: {
         RadialProgressBar,
    },
    props: {
        c_json: {type:Array},
        d_json: {type:Array},
        f_json: {type:Array},
        s_no: {type:Number},
        lang: {type:String},
        csrf: {type: String,required: true},
    },
    data() {
        return{
            files: null,
            is_limit_file: false,
            up_files: [],
            up_files_temp: [],
            up_files_size: 0,
            up_files_total_size: 0,
            upfilename: null,
            dlfilename: null,
            uploading: false,
            downloading: false,
            progress_total: 0,
            progress_total_temp: 0,
            progress: 0,
            modal_value: null,
            modal_type: null,
            check_d: [],
            check_f: [],
            r_c_json: this.c_json,
            r_d_json: this.d_json,
            r_f_json: this.f_json,
            source: null,
            r_s_no: this.s_no,
            r_lang: this.lang,
            order_item: "name",
            order_status: true,
            tree_data_ini: [
                {
                    "text": "home",
                    "opened": true,
                    "path": "",
                    "flag": false
                }
            ],
            tree_data: [
                {
                    "text": "home",
                    "opened": true,
                    "path": "",
                    "flag": false
                }
            ],
            jump_flag: false
        }
    },
    computed: {
        progressWidth: function(){
             return "width:" + this.progress + "%";
        }
    },
    created: function(){
        if(this.r_s_no == 1) this.onItemSort("name",false,false); //1: フォルダ/ファイル↓
        else if(this.r_s_no == 2) this.onItemSort("date",true,false); //2: 更新日↓
        else if(this.r_s_no == 3) this.onItemSort("date",false,false); //3: 更新日↑
        else if(this.r_s_no == 4) this.onItemSort("size",true,false); //4: サイズ↑
        else if(this.r_s_no == 5) this.onItemSort("size",false,false); //5: サイズ↓
        else this.onItemSort("name",true,false); //0: フォルダ/ファイル↑
    },
    mounted: function(){
        if(this.r_lang !== "" && this.r_lang !== null) this.changeLocale();
    },
    methods: {
        changeLocale(){
            this.$i18n.locale = this.r_lang;
        },
        onShowModal(value,type) {
            if(this.downloading){
                alert(this.$t("Wait-Message-Download"));
                return;
            }
            if(this.uploading){
                alert(this.$t("Wait-Message-Upload"));
                return;
            }
            this.modal_value = value;
            this.modal_type = type;

            if(type === "jump"){
                this.$modal.show('modal-02');
            }else{
                this.$modal.show('modal-01');
            }
        },
        hide : function () {
            this.$modal.hide('modal-01');
        },
        hide2 : function () {
            this.$modal.hide('modal-02');
        },
        onProgress(e){
            this.progress = Math.floor((e.loaded * 100) / e.total);
            this.progress_total = Math.floor(((e.loaded + this.progress_total_temp) * 100) / this.up_files_total_size);
        },
        onReViewPrev(e){
            if(this.downloading){
                alert(this.$t("Wait-Message-Download"));
                return;
            }
            if(this.uploading){
                alert(this.$t("Wait-Message-Upload"));
                return;
            }
            let formData = new FormData();

            let dir_split = this.r_c_json[1].split("/");
            let dname;

            if(dir_split.length === 1){
                dname = "";
                formData.append('type','home');
            }else if(dir_split.length === 2){
                dname = dir_split[0];
                formData.append('type','sub01');
            }else{
                dname = dir_split[0]+"/"+dir_split[1];
                formData.append('type','sub02');
            }
            formData.append('dname',dname);

            axios.post("/home", formData)
                .then(response => {
                    this.check_d = [];
                    this.check_f = [];
                    this.r_c_json = response.data[0];
                    this.r_c_json.splice();
                    this.r_d_json = response.data[1];
                    this.r_d_json.splice();
                    this.r_f_json = response.data[2];
                    this.r_f_json.splice();
                    this.onItemSort(response.data[4][0],response.data[4][1],false);
                })
                .catch(error => {
                    window.location = "/login";
                });
        },
        onReViewFord(e){
            if(this.downloading){
                alert(this.$t("Wait-Message-Download"));
                return;
            }
            if(this.uploading){
                alert(this.$t("Wait-Message-Upload"));
                return;
            }
            let formData = new FormData();

            if(this.r_c_json[0] === "home"){
                formData.append('type','sub01');
                formData.append('dname',e.target.value);
            }else if(this.r_c_json[0] === "sub01"){
                formData.append('type','sub02');
                formData.append('dname',this.r_c_json[1]+"/"+e.target.value);
            }else{
                formData.append('type','sub03');
                formData.append('dname',this.r_c_json[1]+"/"+e.target.value);
            }

            axios.post("/home", formData)
                .then(response => {
                    this.check_d = [];
                    this.check_f = [];
                    this.r_c_json = response.data[0];
                    this.r_c_json.splice();
                    this.r_d_json = response.data[1];
                    this.r_d_json.splice();
                    this.r_f_json = response.data[2];
                    this.r_f_json.splice();
                    this.onItemSort(response.data[4][0],response.data[4][1],false);
                })
                .catch(error => {
                    window.location = "/login";
                });
        },
        onFolderRename(value){
            let name = this.$refs.drename.value;

            for(let i=0; i<this.r_d_json.length; i++){
                if(this.r_d_json[i][1] === name){
                    alert(this.$t("Alert-Folder-Rename-Error-01"));
                    return;
                }
            }

            let formData = new FormData();
            formData.append('bedir',value);
            formData.append('afdir',name);
            formData.append('dname',this.r_c_json[1]);

            axios.post("/drename", formData)
            .then(response => {
                if(response.data[1] === "pattern_check01"){
                    alert(this.$t("Alert-Folder-Rename-Error-02"));
                }else if(response.data[1] === "pattern_check02"){
                    alert(this.$t("Alert-Folder-Rename-Error-03"));
                }else if(response.data[1] === "restriction"){
                    alert(this.$t("Alert-ILLEGAL-ACCESS"));
                }else{
                    this.check_d = [];
                    this.check_d = [];
                    this.r_d_json = response.data[0];
                    this.r_d_json.splice();
                    this.tree_data = this.tree_data_ini;
                    this.tree_data.splice();
                    this.onItemSort(this.order_item,this.order_status,false);
                    this.$modal.hide('modal-01');
                }
            })
            .catch(error => {
                window.location = "/login";
            });
        },
        onItemSort(item,status,save){
            let sort_no
            if(item === "name"){
                if(status){
                    this.r_d_json.sort(function(a,b){return(a[1] > b[1]) ? 1 : -1;});
                    this.r_f_json.sort(function(a,b){return(a[1] > b[1]) ? 1 : -1;});
                    this.order_status = true; 
                    sort_no = 0;
                }else{
                    this.r_d_json.sort(function(a,b){return(a[1] < b[1]) ? 1 : -1;});
                    this.r_f_json.sort(function(a,b){return(a[1] < b[1]) ? 1 : -1;});
                    this.order_status = false;
                    sort_no = 1;
                }
                this.order_item = "name";
            }else if(item === "date"){
                if(status){
                    this.r_d_json.sort(function(a,b){return(a[3] < b[3]) ? 1 : -1;});
                    this.r_f_json.sort(function(a,b){return(a[3] < b[3]) ? 1 : -1;});
                    this.order_status = true;
                    sort_no = 2;
                }else{
                    this.r_d_json.sort(function(a,b){return(a[3] > b[3]) ? 1 : -1;});
                    this.r_f_json.sort(function(a,b){return(a[3] > b[3]) ? 1 : -1;});
                    this.order_status = false;
                    sort_no = 3;
                }
                this.order_item = "date";
            }else{
                if(status){
                    this.r_d_json.sort(function(a,b){return(a[4] > b[4]) ? 1 : -1;});
                    this.r_f_json.sort(function(a,b){return(a[4] > b[4]) ? 1 : -1;});
                    this.order_status = true;
                    sort_no = 4;
                }else{
                    this.r_d_json.sort(function(a,b){return(a[4] < b[4]) ? 1 : -1;});
                    this.r_f_json.sort(function(a,b){return(a[4] < b[4]) ? 1 : -1;});
                    this.order_status = false;
                    sort_no = 5;
                }
                this.order_item = "size";
            }

            if(save){
                let formData = new FormData();
                formData.append('sort_no',sort_no);
                formData.append('dname',this.r_c_json[1]);
                axios.post("/savesort", formData)
                            .then(response => {
                            })
                            .catch(error => {
                                window.location = "/login";
                            });
            }

            this.check_d = [];
            this.check_f = [];
        },
        onMkdir(){
            let value = this.$refs.mkdir.value;
            for(let i=0; i<this.r_d_json.length; i++){
                if(this.r_d_json[i][1] === value){
                    alert(this.$t("Alert-Folder-Rename-Error-01"));
                    return;
                }
            }
            
            let formData = new FormData();
            formData.append('mkdir',value);
            formData.append('dname',this.r_c_json[1]);

            axios.post("/mkdir", formData)
            .then(response => {
                if(response.data[1] === "pattern_check01"){
                    alert(this.$t("Alert-Folder-Rename-Error-02"));
                }else if(response.data[1] === "pattern_check02"){
                    alert(this.$t("Alert-Folder-Rename-Error-03"));
                }else if(response.data[1] === "restriction"){
                    alert(this.$t("Alert-ILLEGAL-ACCESS"));
                }else{
                    this.check_d = [];
                    this.check_f = [];
                    this.r_d_json = response.data[0];
                    this.r_d_json.splice();
                    this.tree_data = this.tree_data_ini;
                    this.tree_data.splice();
                    this.onItemSort(this.order_item,this.order_status,false);
                    this.$modal.hide('modal-01');
                }
            })
            .catch(error => {
                window.location = "/login";
            });
        },
        onDelete(value,type){
            this.$modal.hide('modal-01');
            let formData = new FormData();
            formData.append(type,value);
            formData.append('type',this.r_c_json[0]);
            formData.append('dname',this.r_c_json[1]);
            axios.post("/delete", formData)
            .then(response => {
                this.check_d = [];
                this.check_f = [];
                if(type === "ddelete"){
                    this.r_d_json = response.data;
                    this.r_d_json.splice();
                    this.tree_data = this.tree_data_ini;
                    this.tree_data.splice();
                }else if(type === "fdelete"){
                    this.r_f_json = response.data;
                    this.r_f_json.splice();
                    this.onItemSort(this.order_item,this.order_status,false);
                }
            })
            .catch(error => {
                window.location = "/login";
            });
        },
        onDeleteAll(){
            this.$modal.hide('modal-01');
            let formData = new FormData();
            formData.append('type',this.r_c_json[0]);
            formData.append('dname',this.r_c_json[1]);
            let dtarget = [];
            let ftarget = [];
            this.check_d.forEach((value)=>{
                dtarget.push(this.r_d_json[value][1]);
            });
            this.check_f.forEach((value)=>{
                ftarget.push(this.r_f_json[value][1]);
            });
            formData.append('dtarget',JSON.stringify(dtarget));
            formData.append('ftarget',JSON.stringify(ftarget));
            axios.post("/deleteall", formData)
                .then(response => {
                    this.check_d = [];
                    this.check_f = [];
                    this.r_d_json = response.data[0];
                    this.r_d_json.splice();
                    this.r_f_json = response.data[1];
                    this.r_f_json.splice();
                    this.tree_data = this.tree_data_ini;
                    this.tree_data.splice();
                    this.onItemSort(this.order_item,this.order_status,false);
                })
                .catch(error => {
                    window.location = "/login";
                });
        },
        onDownload(value){
            if(this.downloading){
                alert(this.$t("Wait-Message-Download"));
                return;
            }
            if(this.uploading){
                alert(this.$t("Wait-Message-Upload"));
                return;
            }
            let formData = new FormData();
            formData.append('type',this.r_c_json[0]);
            formData.append('dname',this.r_c_json[1]);
            formData.append('fname',this.r_f_json[value][1]);
            this.r_f_json[value][5] = 1;
            this.dlfilename = this.r_f_json[value][1];
            this.source = this.axios.CancelToken.source();
            this.downloading = true;
            this.r_f_json.splice();
            axios.post("/download",formData,{onDownloadProgress:this.onProgress,cancelToken: this.source.token,responseType:'blob'})
                .then(response => {
                    this.downloading = false;
                    this.r_f_json[value][5] = 0;
                    this.r_f_json.splice();
                    let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    let fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', this.r_f_json[value][1]);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.progress = 0;
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        this.r_f_json[value][5] = 0;
                        this.r_f_json.splice();
                        this.downloading = false;
                        this.progress = 0;
                        this.source = null;
                    }else{
                        window.location = "/login";
                    }
                });
        },
        onDownloadAll(){
            if(this.downloading){
                alert(this.$t("Wait-Message-Download"));
                return;
            }
            if(this.uploading){
                alert(this.$t("Wait-Message-Upload"));
                return;
            }
            let formData = new FormData();
            formData.append('type',this.r_c_json[0]);
            formData.append('dname',this.r_c_json[1]);
            let ftarget = [];
            this.check_f.forEach((value)=>{
                ftarget.push(this.r_f_json[value][1]);
                this.r_f_json[value][5] = 1;
                this.r_f_json.splice();
            })
            formData.append('ftarget',JSON.stringify(ftarget));

            //追加分
            let dtarget = [];
            this.check_d.forEach((value)=>{
                dtarget.push(this.r_d_json[value][1]);
            })
            formData.append('dtarget',JSON.stringify(dtarget));

            let date = new Date();
            let YYYY = date.getFullYear();
            let MM = ('0' + (date.getMonth() + 1)).slice(-2);
            let DD = ('0' + date.getDate()).slice(-2)
            let HH = ('0' + date.getHours()).slice(-2);
            let mm = ('0' + date.getMinutes()).slice(-2);
            let ss = ('0' + date.getSeconds()).slice(-2);
            let ms = date.getMilliseconds();
            let zipname = YYYY + MM + DD + "-" + HH + mm + ss + ".zip";

            let tempZipname = YYYY + MM + DD + HH + mm + ss + ms + ".zip";
            formData.append('zipname',tempZipname);

            this.dlfilename = zipname;
            this.source = this.axios.CancelToken.source();
            this.downloading = true;

            axios.post("/downloadall",formData,{onDownloadProgress:this.onProgress,cancelToken: this.source.token,responseType:'blob'})
                .then(response => {
                    this.check_f.forEach((value)=>{
                        this.r_f_json[value][5] = 0;
                        this.r_f_json.splice();
                    })
                    this.downloading = false;
                    let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    let fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', zipname);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.progress = 0;
                    this.check_f = [];
                    this.check_d = [];
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        this.check_f.forEach((value)=>{
                            this.r_f_json[value][5] = 0;
                            this.r_f_json.splice();
                        })
                        this.downloading = false;
                        this.progress = 0;
                        this.check_f = [];
                        this.check_d = [];
                        this.source = null;
                        this.onDelete(tempZipname,"zdelete");
                    }else{
                        window.location = "/login";
                    }
                });
        },
        async onUpload(){
            if(this.up_files.length === 0) {
                alert(this.$t("Alert-Upload-Error-01"));
                return;
            }

            this.up_files_total_size = 0;
            this.progress_total_temp = 0;
            this.progress_total = 0;

            for(let i=0; i<this.up_files.length; i++){
                this.up_files_total_size += this.up_files[i]["size"];
            }
            let cancelFlag = false;
            for(let i=0; i<this.up_files.length; i++){
                for(let j=0; j<this.up_files[i]["data"].length; j++){
                    if(cancelFlag){
                        this.up_files = [];
                        return;
                    }
                    let formData = new FormData();
                    formData.append('file', this.up_files[i]["data"][j]);
                    formData.append('type',this.r_c_json[0]);
                    formData.append('dname',this.r_c_json[1]);

                    this.source = this.axios.CancelToken.source();
                    this.uploading = true;
                    this.upfilename = this.up_files[i]["data"][j].name;
                    await axios.post('/upload',formData,{onUploadProgress: this.onProgress,cancelToken: this.source.token})
                        .then(response => {
                            this.progress_total_temp += this.up_files[i]["data"][j].size;
                            this.uploading = false;
                            this.progress = 0;
                            this.check_d = [];
                            this.check_f = [];
                            this.r_f_json = response.data;
                            this.r_f_json.splice();
                            this.onItemSort(this.order_item,this.order_status,false);
                        })
                        .catch(error => {
                            if (axios.isCancel(error)) {
                                this.uploading = false;
                                this.progress = 0;
                                this.source = null;
                                cancelFlag = true;
                            }else{
                                alert(this.$t("Alert-Upload-Error-02"));
                                window.location = "/login";
                            }
                        });
                }
            }
            this.up_files = [];
        },
        onUpFileDelete(value){
            if(this.downloading){
                alert(this.$t("Wait-Message-Download"));
                return;
            }
            if(this.uploading){
                alert(this.$t("Wait-Message-Upload"));
                return;
            }
            this.up_files.splice(value,1);
        },
        onSelectFile(e){
            let files = e.target.files ? e.target.files:e.dataTransfer.files;
            if(files.length === 0) return;
            let isLimitFile = false;
            Array.from(files).forEach((file)=>{
                if(file.size >= 4294967296){
                    isLimitFile = true;
                }else{
                    let up_data = {"type":"file","name":file.name,"data":[file],"size":file.size};
                    this.up_files.push(up_data);
                }
            });
            if(isLimitFile) alert(this.$t("Alert-Upload-Size-Limit"));
        },
        onSelectFolder(e){
            let files = e.target.files ? e.target.files:e.dataTransfer.files;
            if(files.length === 0) return;
            let isLimitFile = false;
            this.up_files_temp = [];
            let folderName = (files[0].webkitRelativePath).split("/")[0]
            let fileSize = 0;
            Array.from(files).forEach((file)=>{
                if(file.size >= 4294967296){
                    isLimitFile = true;
                }else{
                    fileSize += file.size;
                    this.up_files_temp.push(file);
                }
            });
            if(this.up_files_temp.length > 0){
                let up_data = {"type":"folder","name":folderName,"data":this.up_files_temp,"size":fileSize};
                this.up_files.push(up_data);
            }
            if(isLimitFile) alert(this.$t("Alert-Upload-Size-Limit"));
        },
        async onDropFile(e){
            let files = e.dataTransfer.files;
            let items = e.dataTransfer.items;

            let entries = [];
            for(let i=0; i<files.length; i++){
                entries.push(items[i].webkitGetAsEntry());
            }

            this.is_limit_file = false;
            for(let i=0; i<entries.length; i++){
                let dataType = "";
                let dataName = files[i].name;
                this.up_files_temp = [];
                this.up_files_size = 0;
                if(entries[i].isDirectory){
                    dataType = "folder";
                }else{
                    dataType = "file";
                }
                await this.traverseFileTree(entries[i]);
                if(this.up_files_temp.length > 0){
                    let up_data = {"type":dataType,"name":dataName,"data":this.up_files_temp,"size":this.up_files_size};
                    this.up_files.push(up_data);
                }
            }
            if(this.is_limit_file) alert(this.$t("Alert-Upload-Size-Limit"));
        },
        traverseFileTree:async function(entry){
            if(entry.isFile){
                let file = await new Promise((resolve) => {
                    entry.file((file) => {
                        resolve(file);
                    });
                });
                if(file.size >= 4294967296){
                    this.is_limit_file = true;
                }else{
                    this.up_files_size += file.size;
                    this.up_files_temp.push(file);
                }
            }
            else if(entry.isDirectory){
                const readAllEntries = function(reader) {
                    return new Promise(function(resolve, reject) {
                        const allEntries = [];
                        const readEntries = function() {
                            reader.readEntries(function(entries) {
                                if (entries.length > 0) {
                                    allEntries.push(...entries);
                                    readEntries();
                                } else {
                                    resolve(allEntries);
                                }
                            });
                        };
                        readEntries();
                    });
                };

                const entries = await readAllEntries(entry.createReader());
                for(let i=0; i<entries.length; i++) {
                    await this.traverseFileTree(entries[i]);
                }
            }
        },
        convertSizeUnit(size){
            let target = 0;
            let unit = "";
            const kb = 1024;
            const mb = Math.pow(kb,2);
            const gb = Math.pow(kb,3);

            if(size === 0){
                return "0.00KB";
            }

            if(size >= gb){
                target = gb;
                unit = "GB";
            }else if(size >= mb){
                target = mb;
                unit = "MB";
            }else{
                target = kb;
                unit = "KB";
            }

            const res = target !== null ? Math.floor((size / target) * 100) / 100 : size;
            return String(res)+unit;
        },
        onCancel(){
            this.source.cancel();
        },
        onTreeClick(node){
            let formData = new FormData();
            let text = node.model.text;
            let path = node.model.path;

            if(path === ""){
                formData.append('type','home');
            }else if(path.split("/").length === 1){
                formData.append('type','sub01');
            }else if(path.split("/").length === 2){
                formData.append('type','sub02');
            }else{
                formData.append('type','sub03');
            }

            formData.append('dir',path);
            formData.append('dname',text);
            if(!node.model.flag){
                if(!this.jump_flag){
                    axios.post("/jump", formData)
                        .then(response => {
                            for(let item of response.data){
                                node.model.addChild(item);
                            }
                            node.model.flag = true;
                        })
                        .catch(error => {
                            window.location = "/login";
                        });
                }
            }else{
                if(node.model.opened){
                    node.model.closeChildren()
                }else{
                    node.model.openChildren()
                }
            }
        },  
        onJumpFolder(){
            let value = this.$refs.jump.value;

            let formData = new FormData();
            if(value === ""){
                formData.append('type','home');
            }else if(value.split("/").length === 1){
                formData.append('type','sub01');
            }else if(value.split("/").length === 2){
                formData.append('type','sub02');
            }else{
                formData.append('type','sub03');
            }

            formData.append('dname',value);

            axios.post("/home", formData)
                .then(response => {
                    if(response.data[3] == "not_exists"){
                        alert(this.$t("Alert-Folder-Path-Error-01"));
                    }else if(response.data[3] == "restriction"){
                        alert(this.$t("Alert-ILLEGAL-ACCESS"));
                    }else if(response.data[3] == "pattern_check01"){
                        alert(this.$t("Alert-Folder-Path-Error-02"));
                    }else if(response.data[3] == "pattern_check02"){
                        alert(this.$t("Alert-Folder-Path-Error-03"));
                    }else{
                        this.check_d = [];
                        this.check_f = [];
                        this.r_c_json = response.data[0];
                        this.r_c_json.splice();
                        this.r_d_json = response.data[1];
                        this.r_d_json.splice();
                        this.r_f_json = response.data[2];
                        this.r_f_json.splice();
                        this.onItemSort(response.data[4][0],response.data[4][1],false);
                        this.$modal.hide('modal-02');
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        customItemClick: function (node ,item, e) {
            this.jump_flag = true;
            let formData = new FormData();
            let path = node.model.path;

            if(path === ""){
                formData.append('type','home');
            }else if(path.split("/").length === 1){
                formData.append('type','sub01');
            }else if(path.split("/").length === 2){
                formData.append('type','sub02');
            }else{
                formData.append('type','sub03');
            }

            formData.append('dname',path);

            axios.post("/home", formData)
                .then(response => {
                    this.check_d = [];
                    this.check_f = [];
                    this.r_c_json = response.data[0];
                    this.r_c_json.splice();
                    this.r_d_json = response.data[1];
                    this.r_d_json.splice();
                    this.r_f_json = response.data[2];
                    this.r_f_json.splice();
                    this.onItemSort(response.data[4][0],response.data[4][1],false);
                    this.$modal.hide('modal-02');
                    this.jump_flag = false;
                })
                .catch(error => {
                    window.location = "/login";
                });
        }
    }
}
</script>
