require('./bootstrap');
window.Vue = require('vue').default;
//window.Vue = require('vue');
import axios from 'axios';
import VueAxios from 'vue-axios';
import VModal from 'vue-js-modal';
import VJstree from 'vue-jstree'
import VueI18n from 'vue-i18n';

import { FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faFolder } from '@fortawesome/free-regular-svg-icons';
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import { faWindowClose } from '@fortawesome/free-regular-svg-icons';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

library.add(faUpload,faDownload,faArrowLeft,faSpinner,faTrashAlt,faFolder,faFolderOpen,faFile,faWindowClose,faPen,faArrowDown,faArrowUp,faArrowRight);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('uploader-component', require('./components/uploader.vue').default);

Vue.use(VueAxios,axios);
Vue.use(VJstree);
Vue.use(VModal);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'ja',
  fallbackLocale: 'ja',
  messages:{
    ja : require('../lang/ja.json'),
    en : require('../lang/en.json')
  }
});


const app = new Vue({
    i18n,
    el: '#app'
});
